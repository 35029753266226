<template>
<div class="keyword-wrapper">
  <div class="col-lg-12 p-0">
    <div class="card">
      <div class="card-header header_options">
        <div class="card-title header_title">
          {{ $t('generic-str.key-word') }}
        </div>
        <!-- <div class="form-group col-12 m-auto mb-0">
          <div class="input-group">
            <input
              placeholder=""
              required="required"
              type="password"
              class="form-control"
            />
            <div class="input-group-prepend">
              <button type="button" class="btn icon_btn m-0">
                <span class="material-symbols-outlined purple-add"> add </span>
              </button>
            </div>
          </div>
        </div> -->
        <div class="opts_card">
          <!-- <button class="btn" v-modal="{ target: 'create-key-modal' }">
            <span class="material-icons add_person"> add </span>
          </button> -->
          <button
            class="btn"
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            @click="deleteMultiple"
            v-bind:disabled="selectedKeywords.length == 0 || isSending"
          >
            <span class="material-symbols-outlined title">delete</span>
          </button>
          <!-- <span class="material-icons-outlined title"> file_upload </span>
          <i class="fas fa-file-export title"></i> -->
        </div>
      </div>
      <div v-if="!keywords.length && fetched" class="card-body">
        <LottieAnimNoData />
        <h5 class="card-title m-t-20">Nenhuma palavra chave cadastrada</h5>
      </div>
      <div class="card-body" v-if="fetched">
        <div class="mb-4">
          <button type="button" class="btn p-0 icon_btn m-0 mr-2" v-modal="{ target: 'create-key-modal' }">
            <span class="material-symbols-outlined purple-add"> add </span>
          </button>
          <span class="text-yup" style="font-weight: 400"
            >{{ $t('generic-str.add') }} {{ $t('generic-str.key-word') }}</span
          >
        </div>
        <div v-if="keywords.length" class="keywords-wrapper">
          <ul class="list-unstyled mb-3 list-options">
            <li class="mb-4">
              <div class="control-group opt-2">
                <label class="control control-checkbox" for="check-all-keywords"
                  ><input
                    id="check-all-keywords"
                    v-model="selectAllKeywords"
                    type="checkbox"
                  />
                  <div class="control_indicator"></div>
                  <span>{{ $t('generic-str.all') }}</span></label
                >
              </div>
            </li>
          </ul>
          <ul
            v-for="(keyword, i) in keywords"
            :key="keyword.id"
            class="list-unstyled mb-3 list-options"
          >
            <li>
              <div class="control-group opt-2">
                <label
                  class="control control-checkbox"
                  :for="'keyword-check' + i"
                >
                  <input
                    v-model="selectedKeywords"
                    :id="'keyword-check' + i"
                    :value="keyword"
                    type="checkbox"
                  />
                  <div class="control_indicator"></div>
                  <span>{{ keyword.keyword }}</span>
                </label>
                <DropdownComponent variant="left" left simple>
                  <template #button>
                    <span class="material-icons-outlined title">more_vert</span>
                  </template>
                  <ButtonComponent  v-modal="{ target: 'key-modal', data: keyword }">
                    {{ $t('generic-str.edit') }}
                  </ButtonComponent>
                  <ButtonComponent @click="remove(keyword)">
                    {{ $t('generic-str.remove') }}
                  </ButtonComponent>
                </DropdownComponent>
              </div>
            </li>
          </ul>
        </div>
        <pagination :totalPages="pages" v-model="currentPage" @change="fetch" />
      </div>
      <div class="loading min-h-300" v-else>
        <LoadingAnim />
      </div>
    </div>
  </div>
  <div class="col-lg-12 p-0 hide">
    <div class="card">
      <!-- Msg Optout -->
      <div class="card-header header_options">
        <div class="card-title header_title">
          {{ $t('warnings.optouts.default') }}
        </div>
        <div class="col-lg-12">
          <button type="button" class="btn p-0 icon_btn m-0 mr-2">
            <span class="material-symbols-outlined purple-add"> add </span>
          </button>
          <span class="text-yup" style="font-weight: 400"
            >{{ $t('generic-str.add') }} {{ $tc('app.message', 1) }}</span
          >
        </div>
        <!-- <div class="opts_card">
                  <span class="material-icons add_person"> person_add </span>
                  <span class="material-icons-outlined title"> file_upload </span>
                  <i class="fas fa-file-export title"></i>
                </div> -->
      </div>
      <div class="card-body msg-optout">
        <div class="col-lg d-flex flex-wrap">
            <!-- <label class="mr-3 mb-0">{{$t('sms.send-msg.tabs.simple.lbl-recipient',) + ': '}}</label> -->
          <textarea class="form-control" rows="1" v-model="optoutMessage"/>
          <button class="btn btn-primary mt-2 ml-auto" @click="saveMessage">{{$t('app.save')}}</button>
        </div>
      </div>
      <!--/Msg Optout -->
    </div>
  </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
import SmsService from '@/services/sms.service';
import WhatsService from '@/services/whatsapp.service';
import Pagination from '@/components/PaginationComponent.vue';
import axios from '@/plugins/axios';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';

const webApi = axios();

export default {
  name: 'KeywordsTable',
  components: {
    Pagination,
    LottieAnimNoData,
    LoadingAnim,
    DropdownComponent,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      paginate: {},
      keywords: [],
      selectedKeywords: [],
      optoutMessage: '',
      form: {
        page: 1,
        channel: 'whatsapp',
      },
      pages: 1,
      currentPage: 1,
    };
  },
  computed: {
    selectAllKeywords: {
      get() {
        if (this.keywords) {
          return this.selectedKeywords.length === this.keywords.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.keywords.forEach((keyword) => {
            selected.push(keyword);
          });
        }

        this.selectedKeywords = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    WhatsService.optoutsSettings().then(
      (response) => {
        console.log('Mounted msg padrao: ', response.data.message);
        this.optoutMessage = response.data.message;
      },
      (error) => {
        this.content = error;
        console.log(JSON.stringify(error.response.data));
      },
    );
    this.$root.$on('KeyWordsReload', () => {
      // your code goes here
      this.fetch();
    });
  },
  methods: {
    fetch(page = 1) {
      this.fetched = false;
      this.currentPage = page;
      webApi.get('/whatsapp/new/keywords').then((response) => {
        console.log('optouts response');
        console.log(response);
        this.keywords = response.data;
        this.fetched = true;
      }, (error) => {
        this.$toast.show({
          title: 'Erro interno, por favor tente novamente mais tarde',
          content: error.message,
          type: 'danger',
        });
        this.isSending = false;
        console.log(error);
      });
    },
    remove(keyword) {
      Swal.fire({
        title: `Remover palavra-chave "${keyword.keyword}"`,
        text: 'Essa ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          webApi.delete(`/whatsapp/new/keywords/${keyword.id}`).then((response) => {
            console.log('optouts response');
            this.fetch();
            this.fetched = true;
          }, (error) => {
            this.$toast.show({
              title: 'Erro interno, por favor tente novamente mais tarde',
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
            console.log(error);
          });
        }
      });
    },
    deleteMultiple() {
      Swal.fire({
        title: 'Remover palavras-chave?',
        text: 'Essa ação não poderá ser desfeita?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedKeywords.forEach((item) => {
            webApi.delete(`/whatsapp/new/keywords/${item.id}`).then((response) => {
              this.fetch();
            }, (error) => {
              this.$toast.show({
                title: 'Erro interno, por favor tente novamente mais tarde',
                content: error.message,
                type: 'danger',
              });
              this.isSending = false;
              console.log(error);
            });
          });
          this.fetch();
          this.isSending = true;
        }
      });
    },
    saveMessage() {
      this.isSending = true;
      WhatsService.optoutsMessage({
        message: this.optoutMessage,
      }).then(
        (response) => {
          console.log(response);
          this.isSending = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('general-settings.toast.saved'),
            type: 'success',
          });
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 768px) and (max-width: 992px) {
  .btn-trim {
    display: none;
  }
}
.card-body {
  min-height: 200px !important;
}
.table-responsive {
  min-height: 300px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
.no-item {
  font-size: 60px;
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
/* .darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
} */
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.header_options .input-group > input,
.header_options .input-group button {
  height: 50px;
}

.rebranding .add_person {
  cursor: pointer;
}

.rebranding .opts_card button {
  height: fit-content;
  padding: 0;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

/* Keyword */
.list-options li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.list-options li .control-group {
  display: flex;
  gap: 10px;
  clear: both;
  width: 100%;
}

.list-options li .control-group .control {
  width: 100%;
}

.msg-optout {
  .control {
    display: flex;
    align-items: center;
  }

  .list-options span {
    font-weight: 400;
  }

  .list-options li .control-group .control {
    width: auto;
  }
}

.list-options li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
}

.rebranding .purple-add {
  background-color: var(--clr-yup-purple);
  color: #fff !important;
  transition: 0.5s;
}

.rebranding .purple-add:hover {
  background-color: var(--clr-yup-purple-hover);
}

.rebranding .card {
  height: calc(100% - 1.875rem);
}

.keyword-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  textarea {
    resize: none;
  }
}

.rebranding .card .card-header .header_title, .rebranding .header_title {
  font-weight: 400;
}
</style>
