<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('key-word-component.new')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >{{$t('key-word-component.label')}}</label
                >
                <div class="col-md-12">
                  <textarea
                    v-model="form.word"
                    type="text"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-yup-purple-outline" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-yup-purple"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Modal from '@/mixins/Modal';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  mixins: [Modal],
  props: ['id'],
  components: {},
  data() {
    return {
      form: {},
      isSending: false,
    };
  },
  methods: {
    save() {
      this.isSending = true;
      webApi.post('/whatsapp/new/keywords', {
        keyword: this.form.word,
      }).then((response) => {
        this.hide();
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: this.$t('key-word-component.success'),
          type: 'success',
        });
        this.$emit('submit');
        this.$root.$emit('KeyWordsReload');
        this.isSending = false;
      }, (error) => {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
          content: error.message,
          type: 'danger',
        });
        this.isSending = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
